<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="indexLayoutContent" style="padding: 0px">
          <div class="page flex-col">
            <div :class="`bardiv ${barclass}`">
              <div class="barlist">
                <template v-for="(item, index) in blinks">
                  <a class="bla" v-if="1" :key="index">
                    <div
                      class="blitem"
                      :key="index"
                      @click="
                        () => {
                          goDiv(item.class, item.url);
                        }
                      "
                    >
                      <span>{{ item.title }}</span>
                      <div class="lined">
                        <img
                          class="line"
                          referrerpolicy="no-referrer"
                          src="../assets/imgs/line.png"
                        />
                      </div>
                    </div>
                  </a>
                </template>

                <div
                  class="blitem justify-between"
                  style="flex-direction: row"
                  @click="
                    () => {
                      goDiv(item.class, item.url);
                    }
                  "
                >
                  <span
                    v-if="lag == 'en'"
                    @click="
                      () => {
                        changelag('');
                      }
                    "
                    >中</span
                  >
                  <span v-else style="color: rgba(231, 55, 74, 1)">中</span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span v-if="lag == 'en'" style="color: rgba(231, 55, 74, 1)"
                    >EN</span
                  >
                  <span
                    v-else
                    @click="
                      () => {
                        changelag('en');
                      }
                    "
                    >EN</span
                  >
                </div>

                <!-- <span
                  :class="`${
                    lag == 'en' ? 'item' : 'itemf'
                  } flex-col justify-between`"
                  @click="
                    () => {
                      changelag('');
                    }
                  "
                >
                  <span class="itemtext">中</span>
                </span>
                <span
                  class="item flex-col justify-between"
                  style="margin-left: 5px"
                >
                  <span class="itemtext">/</span>
                </span>
                <span
                  :class="` ${
                    lag == 'en' ? 'itemf' : 'item'
                  }  flex-col justify-between`"
                  style="margin-left: 5px"
                  @click="
                    () => {
                      changelag('en');
                    }
                  "
                >
                  <span class="itemtext">EN</span>
                </span> -->
              </div>
              <div class="toplan">
                <div class="image-wrapper_1 flex-col justify-between w-logo">
                  <img
                    class="w-logo-img"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/logo.png"
                  />
                </div>

                <div class="closebar" @click="closeBardiv">
                  <img src="../assets/imgs/icon-close2.png" />
                </div>
              </div>

              <div class="bltouch" @click="closeBardiv"></div>
            </div>

            <div class="group_1 flex-col">
              <div class="group_3 flex-row" v-if="barclass == 'closethis'">
                <div class="toplan">
                  <div
                    class="image-wrapper_1 flex-col justify-between w-logo wllogo"
                  >
                    <img
                      class="w-logo-img"
                      referrerpolicy="no-referrer"
                      src="../assets/imgs/logo.png"
                    />
                  </div>

                  <div class="blinks justify-end">
                    <template v-for="(item, index) in blinks">
                      <template v-if="index < blinks.length - 1">
                        <span
                          @mouseover="
                            () => {
                              blinkshandleHover(index);
                            }
                          "
                          @touchstart="
                            () => {
                              blinkshandleHover(index);
                            }
                          "
                          v-if="bselectindex != index"
                          class="item flex-col justify-between"
                          :key="index"
                        >
                          <span class="itemtext">{{ item.title }}</span>
                        </span>
                        <span
                          @mouseleave="blinkshandleLeave"
                          @click="
                            () => {
                              goDiv(item.class, item.url);
                            }
                          "
                          v-if="bselectindex == index"
                          class="itemf flex-col justify-between"
                          :key="index"
                        >
                          <span class="itemtext">{{ item.title }}</span>

                          <div class="lined">
                            <img
                              class="line"
                              referrerpolicy="no-referrer"
                              src="../assets/imgs/line.png"
                            />
                          </div>
                        </span>
                      </template>
                    </template>

                    <span
                      @click="
                        () => {
                          goDiv(
                            blinks[blinks.length - 1].class,
                            blinks[blinks.length - 1].url
                          );
                        }
                      "
                      :class="`${
                        topbarOpacity >= 1 ? 'itemfsf' : 'itemfs'
                      } flex-col justify-between`"
                      v-if="blinks[blinks.length - 1].title != ''"
                    >
                      <span class="itemtext">{{
                        blinks[blinks.length - 1].title
                      }}</span>
                    </span>

                    <span
                      :class="`${
                        lag == 'en' ? 'item' : 'itemf'
                      } flex-col justify-between`"
                      @click="
                        () => {
                          changelag('');
                        }
                      "
                    >
                      <span class="itemtext">中</span>
                    </span>
                    <span
                      class="item flex-col justify-between"
                      style="margin-left: 5px"
                    >
                      <span class="itemtext">/</span>
                    </span>
                    <span
                      :class="` ${
                        lag == 'en' ? 'itemf' : 'item'
                      }  flex-col justify-between`"
                      style="margin-left: 5px"
                      @click="
                        () => {
                          changelag('en');
                        }
                      "
                    >
                      <span class="itemtext">EN</span>
                    </span>
                  </div>

                  <div
                    class="showbar"
                    @click="openBardiv"
                    v-if="barclass == 'closethis'"
                  ></div>
                </div>

                <div class="darkback" :style="{ opacity: topbarOpacity }"></div>
              </div>

              <div
                class="group_2 flex-row"
                :style="`background-image: url(${baseUrl}${
                  w750 == 1 ? basedata.tback0m : basedata.tback0
                }); 
                  ${m_group_2_height != 0 ? `height:${m_group_2_height}px` : ``}
                `"
              >
                <span class="text_1" style="z-index: 999">Creativity</span>
                <span class="text_2" style="z-index: 999"
                  >inspiration-driven</span
                >

                <video
                  v-if="w750 == 0 && basedata.video0show == 'true'"
                  id="video0"
                  :show-center-play-btn="false"
                  :controls="false"
                  style="
                    position: absolute;
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                    background-color: none;
                  "
                  preload="load"
                  playsinline="true"
                  webkit-playsinline="true"
                  x-webkit-airplay="allow"
                  airplay="allow"
                  x5-video-player-type="h5"
                  x5-video-player-fullscreen="true"
                  x5-video-orientation="portrait"
                  :src="baseUrl + basedata.video0"
                  autoplay
                  muted
                  loop
                ></video>
              </div>
            </div>

            <div class="group_5 flex-row justify-between">
              <div
                class="jianjie"
                style="margin-top: -12rem; position: absolute"
              ></div>
              <div class="jjdiv flex-col justify-between">
                <div class="jjd-0 flex-col">
                  <span class="jjd-0-0">
                    <img class="dh" src="../assets/imgs/dh.png" />
                  </span>
                  <span class="jj-title" v-html="basedata.mtitle"></span>
                </div>
                <div class="jjd-1">
                  <span class="paragraph_1" v-html="basedata.mcontent"> </span>
                </div>
              </div>
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="../assets/imgs/icon0.png"
              />
            </div>

            <span class="text_12" style="text-transform: uppercase">
              <div
                class="huoban"
                style="position: absolute; margin-top: -12rem"
              ></div>
              {{ basedata.t1 }}</span
            >

            <div class="hblist flex-col">
              <div class="swiper-container sc2">
                <div class="swiper-wrapper">
                  <template v-for="(item, index) in frienddata2">
                    <div class="swiper-slide ss2" v-if="1" :key="index">
                      <template v-for="(item2, index2) in item">
                        <a class="limga"  v-if="1" :key="index2">
                          <div class="litem" @click="windowopen(item2.url)">
                            <img
                              class="limg"
                              referrerpolicy="no-referrer"
                              :src="baseUrl + item2.imgurl"
                            />
                          </div>
                        </a>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <!-- <div class="hblist flex-col">
              <div class="image-wrapper_8 flex-row">
                <template v-for="(item, index) in frienddata">
                  <a class="limga" :key="index">
                    <div class="litem" @click="windowopen(item.url)">
                      
                      <img
                        class="limg"
                        referrerpolicy="no-referrer"
                        :src="baseUrl + item.imgurl"
                      />

                    </div>
                  </a>
                </template>
              </div>
            </div> -->

            <div class="sections flex-row justify-between">
              <div
                class="fuwuneirong"
                style="position: absolute; top: -12rem"
              ></div>

              <template v-for="(item, index) in sections">
                <div
                  :class="`section_div flex-col`"
                   v-if="1"
                  :key="index"
                  :style="`cursor:pointer;`"
                  @mouseover="
                    () => {
                      sectionhandleHover(index);
                    }
                  "
                  @click="
                    () => {
                      selectclass2(item.cid);
                    }
                  "
                >
                  <div class="section_back">
                    <div
                      v-if="
                        sectionindex == index ||
                        ((index == 0 || index == 2) && w750 == 1)
                      "
                      class="section_red_back"
                    >
                      <div class="section_red_back_in"></div>
                    </div>
                    <div class="section_logo flex-row">
                      <img
                        class="i-logo"
                        referrerpolicy="no-referrer"
                        :src="`assets/textures/${item.img}`"
                      />
                    </div>
                    <div class="section_title flex-row">
                      <span
                        class="section_title_text"
                        v-html="item.title"
                      ></span>
                      <div class="section_line"></div>
                    </div>

                    <div class="section_text_0" v-html="item.desc"></div>

                    <template v-for="(item2, index2) in item.tip">
                      <div
                        class="section_text_1 flex-row justify-start"
                         v-if="1"
                        :key="index2"
                      >
                        <span class="section_text_1_icon">●&nbsp;</span>
                        <span class="section_text_1_text" v-html="item2"></span>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>

            <span class="text_51" style="text-transform: uppercase">
              <div class="anli" style="position: absolute; top: -12rem"></div>
              {{ basedata.t3 }}</span
            >
            <div class="group_10 flex-row justify-between">
              <div class="mkdiv flex-row justify-between">
                <a class="mkitema">
                  <div
                    :class="`mkitem ${
                      thisclass2 == '' ? 'selected' : ''
                    } flex-col`"
                    style="padding-left: 3rem; padding-right: 3rem"
                    @click="
                      () => {
                        selectclass2('');
                      }
                    "
                  >
                    <span class="mktext" v-if="lag == 'en'">ALL</span>
                    <span class="mktext" v-else>全部</span>
                  </div>
                </a>

                <template v-for="(item, index) in mkinds">
                  <a class="mkitema" v-if="1" :key="index">
                    <div
                      :class="`mkitem flex-col  ${
                        thisclass2 == item.id ? 'selected' : ''
                      }`"
                      @click="
                        () => {
                          selectclass2(item.id);
                        }
                      "
                    >
                      <span class="mktext" v-html="item.name"></span>
                    </div>
                  </a>
                </template>
              </div>
              <!-- <img
                class="image_21"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngeda3e001b1ab1f2ff8ccdbb40d2fa36bc422f9eaebe86adbeab1f91224c04650"
              /> -->

              <div class="images_more_list" v-if="w750 == 0">
                <div class="imlitem imli0"></div>
                <div class="imlitem imli1"></div>
                <div class="imlitem imli2"></div>
                <div class="imlitem imli3"></div>
              </div>
            </div>

            <div class="pplist flex-row justify-between">
              <svg
                v-if="dataloading"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="8rem"
                height="10rem"
                style="
                  width: 8rem;
                  height: 10rem;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -5rem;
                  margin-left: -4rem;
                  enable-background: new 0 0 50 50;
                "
                viewBox="0 0 24 30"
                xml:space="preserve"
              >
                <rect x="0" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="10" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
                <rect x="20" y="13" width="4" height="5" fill="#CCCCCC">
                  <animate
                    attributeName="height"
                    attributeType="XML"
                    values="5;21;5"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                  <animate
                    attributeName="y"
                    attributeType="XML"
                    values="13; 5; 13"
                    begin="0.3s"
                    dur="0.6s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </svg>

              <template v-if="data.length > 0">
                <template v-for="(item, index) in data">
                  <div
                    :class="`ppitem ${ppindex == index ? 'ppselected' : ''}`"
                    :style="`animation-delay: ${index * 0.1}s;`"
                    v-if="1"
                    :key="index"
                    @mouseover="
                      () => {
                        pphandleHover(index);
                      }
                    "
                    @touchstart="
                      () => {
                        pphandleHover(index);
                      }
                    "
                    @mouseleave="pphandleLeave"
                  >
                    <img
                      :class="`ppimg`"
                      referrerpolicy="no-referrer"
                      :src="`${baseUrl + item.imgary[0].url}`"
                    />
                    <!-- <img
                      :class="`ppimg ${ppindex == index ? 'ppimgshow' : ''}`"
                      referrerpolicy="no-referrer"
                      :src="`${baseUrl + item.imgary[0].url}`"
                    /> -->

                    <div
                      class="block_6 flex-col"
                      @click="
                        () => {
                          if (item.url == '') {
                            swSlideTo(item.swiperindex, item.id);
                          } else {
                            gowebsite(item.url);
                          }
                        }
                      "
                    >
                      <!-- <div
                      class="block_6 flex-col"
                      v-if="ppindex == index"
                      @click="
                        () => {
                          if (item.url == '') {
                            swSlideTo(item.swiperindex, item.id);
                          } else {
                            gowebsite(item.url);
                          }
                        }
                      "
                    > -->
                      <div class="ppinfo" style="margin-bottom: 0rem">
                        <span
                          class="text_57_240227"
                          v-if="lag == 'en' && item.enpname != ''"
                          v-html="item.enpname"
                          style="color: #333"
                        ></span>
                        <span
                          class="text_57_240227"
                          v-else
                          v-html="item.pname"
                          style="color: #333"
                        ></span>

                        <span
                          class="des_57_240227"
                          v-if="lag == 'en' && item.endes != ''"
                          v-html="item.endes"
                          style="color: #666"
                        ></span>
                        <span
                          class="des_57_240227"
                          v-else
                          v-html="item.des"
                          style="color: #666"
                        ></span>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="nopplistshow">
                <div class="nopplist">- NONE -</div>
              </template>
            </div>

            <a
              class="ahover"
              :href="lag == 'en' ? `#/plist/index/en` : `#/plist/index`"
            >
              <div class="ppmore flex-col">
                <span v-if="lag == 'en'" class="text">MORE</span>
                <span v-else class="text">更多案例</span>
              </div>
            </a>

            <div
              class="group_69 flex-col justify-center"
              v-if="adata.length > 0"
            >
              <span class="text_70" style="text-transform: uppercase">
                <div
                  class="rongyu"
                  style="position: absolute; top: -12rem"
                ></div>
                {{ basedata.t45 }}</span
              >

              <div class="section_20 flex-col"></div>
              <template v-for="(item, index) in adata">
                <div
                  :class="`awardsdiv ${
                    awardsindex == index ? 'awardsshow' : ''
                  }`"
                  v-if="1"
                  :key="index"
                  @mouseover="
                    () => {
                      awardshandleHover(index);
                    }
                  "
                  @touchstart="
                    () => {
                      awardshandleHover(index);
                    }
                  "
                  @click="
                    () => {
                      if (item.url == '') {
                        sw3SlideTo(item.swiperindex, item.id);
                      } else {
                        gowebsite(item.url);
                      }
                    }
                  "
                >
                  <div class="group_80 flex-row justify-between">
                    <img
                      class="image_176"
                      referrerpolicy="no-referrer"
                      :src="`${baseUrl + item.imgary[0].url}`"
                    />

                    <div class="text-wrapper_159 flex-col">
                      <template v-if="lag == 'en' && item.enpname != ''">
                        <span class="text_71">{{ item.enpname }}</span>
                      </template>
                      <template v-else>
                        <span class="text_71">{{ item.pname }}</span>
                      </template>

                      <template v-if="lag == 'en' && item.endes != ''">
                        <span class="text_72">{{ item.endes }}</span>
                      </template>
                      <template v-else>
                        <span class="text_72">{{ item.des }}</span>
                      </template>

                      <span v-if="lag == 'en'" class="text_73"
                        >View details</span
                      >
                      <span v-else class="text_73">查看详情</span>
                    </div>
                  </div>
                  <div class="section_22 flex-col"></div>
                </div>
              </template>
            </div>

            <div
              class="group_69 flex-col justify-center"
              style="background-color: #302c33; margin-top: 0px"
              v-if="adata.length > 0"
            >
              <div :class="`awardsdiv`">
                <div class="group_80 flex-row justify-between">
                  <img
                    class="image_176"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/jx1.png"
                  />

                  <div class="text-wrapper_159 flex-col">
                    <span class="text_71_1"
                      >2023年12月，获得全球权威的可持续发展评级机构Ecovadis“铜牌”评级。</span
                    >
                    <span class="text_71_1"
                      >EcoVadis是一个全球公认的平台，提供对各行业公司可持续性表现的评级。它对企业在环境、社会和道德实践方面进行评估，提供全面的评估报告，帮助公司提升其可持续发展表现。
                    </span>
                  </div>
                </div>
                <div class="section_22 flex-col"></div>
              </div>
              <div :class="`awardsdiv`">
                <div class="group_80 flex-row justify-between">
                  <img
                    class="image_176"
                    referrerpolicy="no-referrer"
                    src="../assets/imgs/jx2.png"
                  />

                  <div class="text-wrapper_159 flex-col">
                    <span class="text_71_1"
                      >2024年07月，上海派志承诺以2023年为基准年，到2030年范围一、范围二温室气体
                      排放量减少42%，并测量和减少范围三的排放，以支持1.5℃限温的减排目标。</span
                    >
                    <!-- <span class="text_72"
                      >EcoVadis是一个全球公认的平台，提供对各行业公司可持续性表现的评级。它对企业在环境、社会和道德实践方面进行评估，提供全面的评估报告，帮助公司提升其可持续发展表现。
                    </span> -->
                  </div>
                </div>
                <div class="section_22 flex-col"></div>
              </div>
              <!-- <div :class="`awardsdiv`">
                <div class="group_80 flex-row justify-between">
                  <div class="imagetotext_176">
                    <div class="t">多元化与包容性</div>
                    <div>（I&D）</div>
                  </div>

                  <div class="text-wrapper_159 flex-col">
                    <span class="text_71_1"
                      >上海派志计划通过员工教育和培训加强领导团队的多元化与包容性和ESG能力建设，让他们的知识和经验成为企业可持续发展的财富。</span
                    >
                    <span class="text_71_1">
                      <strong>01.</strong> 建设多元化与包容性的领导团队，积极采取措施消除职场性别歧视，制定公正的招聘和晋升政策；
                    </span>
                    <span class="text_71_1">
                      <strong>02.</strong> 以客户为中心，打破跨代和跨层级沟通壁垒，建立更有效的协作机制。鼓励不同年龄及层级之间的交流和合作。培养年轻一代的领导潜力，发挥他们在创新、开放性思维、快速学习能力等方面的优势，同时重视年长员工的经验和智慧。建立更有效的协作机制，并辅以数字化、多元化的沟通渠道让年轻人的声音可以传导到决策层，加速知识共享和技能传承。通过盘活人才资源、激发创新活力，提高业务的竞争力，抓住新机遇，并实现业务成果并促进新的增长曲线。
                    </span>
                    <span class="text_71_1">
                      <strong>03.</strong> 建立多元化与包容性的企业文化
                    </span>
                    <span class="text_71_1">
                      <strong>04.</strong> 选择具有多元化和包容性理念的合作伙伴，促进整个供应链的多样性
                    </span>
                  </div>
                </div>
                <div class="section_22 flex-col"></div>
              </div> -->
            </div>

            <footer-index :w750="w750" :basedata="basedata" :lag="lag" />
          </div>

          <div class="showppdiv">
            <div class="closesw"></div>
            <div class="swiper-container sc">
              <div class="swiper-wrapper">
                <template v-for="(item, index) in data">
                  <div
                    class="swiper-slide ss0"
                    v-if="item.url == ''"
                    :key="index"
                  >
                    <div class="ss ss0">
                      <div class="ssin">
                        <div class="scontent">
                          <!-- <div class="sstitle" v-html="item.pname"></div> -->
                          <div
                            class="sstitle"
                            v-if="lag == 'en' && item.enpname != ''"
                            v-html="item.enpname"
                          ></div>
                          <div class="sstitle" v-else v-html="item.pname"></div>

                          <div
                            class="ppkinds flex-row justify-start"
                            v-if="lag == 'en' && item.enkeywords != ''"
                          >
                            <template
                              v-for="(item2, index2) in item.enkeywords.split(
                                ','
                              )"
                            >
                              <div
                                class="ppkitem flex-col"
                                v-if="1"
                                :key="index2"
                              >
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div>
                          <div class="ppkinds flex-row justify-start" v-else>
                            <template
                              v-for="(item2, index2) in item.keywords.split(
                                ','
                              )"
                            >
                              <div
                                class="ppkitem flex-col"
                                v-if="1"
                                :key="index2"
                              >
                                <span class="ppktext" v-html="item2"></span>
                              </div>
                            </template>
                          </div>

                          <div
                            class="ppcontent"
                            v-if="lag == 'en' && item.encontent != ''"
                          >
                            <div
                              v-if="item.swiperindex == selectIndex"
                              v-html="item.encontent"
                            ></div>
                          </div>
                          <div class="ppcontent" v-else>
                            <div
                              v-if="item.swiperindex == selectIndex"
                              v-html="item.content"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>

          <div class="showawardsdiv">
            <div class="closesw3"></div>
            <div class="swiper-container sc3">
              <div class="swiper-wrapper">
                <template v-for="(item, index) in adata">
                  <div
                    class="swiper-slide ss0"
                    v-if="item.url == ''"
                    :key="index"
                  >
                    <div class="ss ss0">
                      <div class="ssin">
                        <div class="scontent">
                          <!-- <div class="sstitle" v-html="item.pname"></div> -->
                          <div
                            class="sstitle"
                            v-if="lag == 'en' && item.enpname != ''"
                            v-html="item.enpname"
                          ></div>
                          <div class="sstitle" v-else v-html="item.pname"></div>

                          <div
                            class="ppcontent"
                            v-if="lag == 'en' && item.encontent != ''"
                          >
                            <div
                              v-if="index == selectIndex3"
                              v-html="item.encontent"
                            ></div>
                          </div>
                          <div class="ppcontent" v-else>
                            <div
                              v-if="index == selectIndex3"
                              v-html="item.content"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="swiper-pagination"></div>
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>

          <!-- <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer> -->
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  fetchCase,
  fetchFriend,
  fetchBase,
  fetchAwards,
} from "../api/index";
import FooterIndex from "../components/FooterIndex.vue";
// const Base64 = require('js-base64').Base64
import Swiper from "swiper";
import "swiper/css/swiper.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    FooterIndex,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      scshow: false,
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper2: null,

      swiper3: null,

      selectIndex: 0,
      selectIndex3: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      blinks: [
        { title: "", url: "", class: "jianjie" },
        { title: "", url: "", class: "huoban" },
        { title: "", url: "", class: "fuwuneirong" },
        { title: "", url: "", class: "anli" },
        { title: "", url: "", class: "lianxiwomen" },
        { title: "", url: "tech", class: "" },
      ],
      sectionindex: 0,
      sections: [],
      chsections: [
        {
          img: "i-logo-0.png",
          title: "展览展厅&amp;活动",
          desc: "提供前沿的创意理念，及线下制作的一体化服务",
          tip: ["临时展厅", "常设展厅", "多媒体互动", "交互内容", "线下活动"],
          cid: 70,
        },
        {
          img: "i-logo-1.png",
          title: "数字营销&amp;视频创作",
          desc: "覆盖线上、线下，整合全渠道的推广传播",
          tip: ["数字营销", "社会化传播", "TVC视频", "宣传片"],
          cid: 71,
        },
        {
          img: "i-logo-2.png",
          title: "智能平台&amp;AIGC",
          desc: "提供服务与解决方案的 智能科技应用平台，AIGC 工作流赋能创意策划",
          tip: ["智能会务系统", "数字孪生", "智能机器人", "AIGC"],
          cid: 72,
        },
        {
          img: "i-logo-3.png",
          title: "奢侈品营销",
          desc: "结合线下制作、运营于一体的互动体验",
          tip: ["促销活动", "体验营销"],
          cid: 73,
        },
      ],
      ensections: [
        {
          img: "i-logo-0.png",
          title: "Exhibition & Event",
          desc: "Provide cutting-edge creative concepts and integrated services for offline production",
          tip: ["Exhibition", "Muti-Media", "Interactive", "Physical Event"],
          cid: 70,
        },
        {
          img: "i-logo-1.png",
          title: "Digital Marketing & <br />Video Creation",
          desc: "Covering both online and offline,Integrate omnichannel promotion and dissemination",
          tip: [
            "Digital marketing",
            "Social Communication",
            "TVC Video",
            "Promotional video",
          ],
          cid: 71,
        },
        {
          img: "i-logo-2.png",
          title: "Intelligence Platform & <br />Multi Interactive",
          desc: "An intelligent technology application platform that provides services and solutions",
          tip: [
            "Intelligent conference system",
            "Intelligent Virtual Event",
            "Digital twin",
            "intelligent robot",
          ],
          cid: 72,
        },
        {
          img: "i-logo-3.png",
          title: "Luxury",
          desc: "Interactive experience combining offline production and operation",
          tip: ["Promotional activities", "Experience Marketing"],
          cid: 73,
        },
      ],
      data: [],
      dataloading: 0,
      pageSize: 6,
      query: {
        class1: 68,
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      let video0 = document.getElementById("video0");
      video0.play();
    }, 1000);
    this.alive = true;
    this.handleScroll;

    // if (that.w750 == 0) {
    //     if (that.swiper2) {
    //       that.swiper2.destroy(false, true);
    //     }
    //     that.myswiper2();
    //   } else {
    //     if (that.swiper2) {
    //       that.swiper2.destroy(false, true);
    //     }
    //     that.myswiper2m();
    //   }
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    let that = this;

    let lag = this.$route.params.lag;

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    global.setIndexDclass("");
    global.setIndexscroll(0);
    this.handleScroll;
    that.resizepage();
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleResize, true);
    this.myswiper();
    this.myswiper3();
    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        let videos = $(".showppdiv").find("video");

        for (var i = 0; i < videos.length; i++) {
          console.log("video:", videos[i]);
          videos[i].pause();
        }

        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
          // $(".showppdiv").find("video").pause();
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);
    that.getClassList();
    that.fetch();
    that.fetchf();
    that.fetchb();
    that.fetcha();
  },
  beforeCreate() {
    let that = this;
  },
  methods: {
    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch(
      // params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
      params = { show: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        setTimeout(() => {
          that.swiper.update();
        }, 100);
      });
    },
    fetchf(params = { show: 1, pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        // console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        let frienddata2 = [];

        let lindex = 0;
        res.results.list.map((item, index) => {
          if (index % 4 == 0) {
            frienddata2[lindex] = [];
          }
          frienddata2[lindex].push(item);
          if (index % 4 == 3) {
            lindex++;
          }
        });
        that.frienddata2 = frienddata2;

        // console.log("frienddata:", that.frienddata);
        // console.log("frienddata2:", that.frienddata2);

        if (that.w750 == 0) {
          that.myswiper2();
        } else {
          that.myswiper2m();
        }
      });
    },
    fetcha(params = { show: 1, hot: 1, pagesize: this.pageSize }) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchAwards(params).then((res) => {
        console.log("fetchAwards:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;

        if (res.results.list) {
          that.adata = res.results.list;
        } else {
        }
        that.pagination = pagination;
        // console.log("data:", that.data);
        setTimeout(() => {
          that.swiper3.update();
        }, 100);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        // console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        that.blinks[0].title = res.results.t0;
        that.blinks[1].title = res.results.t1;
        that.blinks[2].title = res.results.t2;
        that.blinks[3].title = res.results.t3;
        that.blinks[4].title = res.results.t4;
        that.blinks[5].title = res.results.t5;
        // that.blinks[6].title = res.results.t6;
        // that.blinks[7].title = res.results.t7;
        // that.blinks[8].title = res.results.t8;
        // that.blinks[9].title = res.results.t9;
        // that.blinks[10].title = res.results.t10;

        if (that.lag == "en") {
          that.blinks[0].title = res.results.ent0;
          that.blinks[1].title = res.results.ent1;
          that.blinks[2].title = res.results.ent2;
          that.blinks[3].title = res.results.ent3;
          that.blinks[4].title = res.results.ent4;
          that.blinks[5].title = res.results.ent5;
          // that.blinks[6].title = res.results.ent6;
          // that.blinks[7].title = res.results.ent7;
          // that.blinks[8].title = res.results.ent8;
          // that.blinks[9].title = res.results.ent9;
          // that.blinks[10].title = res.results.ent10;

          that.basedata.t0 = res.results.ent0;
          that.basedata.t1 = res.results.ent1;
          that.basedata.t2 = res.results.ent2;
          that.basedata.t3 = res.results.ent3;
          that.basedata.t4 = res.results.ent4;
          that.basedata.t45 = res.results.ent45;
          that.basedata.t5 = res.results.ent5;
          that.basedata.t6 = res.results.ent6;
          that.basedata.t7 = res.results.ent7;
          that.basedata.t8 = res.results.ent8;
          that.basedata.t9 = res.results.ent9;
          that.basedata.t10 = res.results.ent10;

          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
          that.basedata.mtitle = res.results.enmtitle;
          that.basedata.mcontent = res.results.enmcontent;
          that.basedata.aititle = res.results.enaititle;
          that.basedata.aicontent = res.results.enaicontent;
          that.basedata.aicontent0 = res.results.enaicontent0;
          that.basedata.aicontent1 = res.results.enaicontent1;
          that.basedata.aicontent2 = res.results.enaicontent2;
          that.basedata.aicontent3 = res.results.enaicontent3;
          that.basedata.aicontent4 = res.results.enaicontent4;
        }
      });
    },

    selectclass2(id) {
      console.log("id:", id);
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];

      this.goDiv("anli", "");

      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        // console.log(
        //   "%cfetchClass",
        //   "color: green;font-size: 14px;font-weight: bold;",
        //   res
        // );
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    swSlideTo(index, id) {
      // alert(this.w750);

      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetall/${d}/en`);
        } else {
          this.$router.push(`/pdetall/${id}`);
        }
        return false;
      }
      let that = this;

      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },

    sw3SlideTo(index, id) {
      console.log(index, id);

      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/adetall/${d}/en`);
        } else {
          this.$router.push(`/adetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper3.slideTo(index, 0);
      $(".showawardsdiv").css({ left: "0%" });
      $(".showawardsdiv").fadeTo(300, 1, () => {});
    },
    gowebsite(url) {
      window.open(url);
    },
    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".sc", {
          duration: 1000,
          pagination: {
            // el: '.swiper-pagination',
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            transitionEnd: function () {
              that.selectIndex = this.activeIndex;
              console.log(that.selectIndex);
            },
            init: function (swiper) {
              this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
            },
          },
        });
      }, 10);
    },
    myswiper3() {
      let that = this;

      setTimeout(() => {
        that.swiper3 = new Swiper(".sc3", {
          duration: 1000,
          pagination: {
            // el: '.swiper-pagination',
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            transitionEnd: function () {
              that.selectIndex3 = this.activeIndex;
              // console.log(that.selectIndex);
            },
            init: function (swiper) {
              this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
            },
          },
        });
      }, 10);
    },

    myswiper2() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc2", {
          speed: 2000,
          slidesPerView: 5,
          slidesPerColumn: 1,
          spaceBetween: 0,
          // freeMode: true,
          loop: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 10);
    },

    myswiper2m() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc2", {
          speed: 2000,
          slidesPerView: 2,
          slidesPerColumn: 1,
          spaceBetween: 0,
          // freeMode: true,
          loop: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 10);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },
    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;
      if (window.innerWidth > 1920) {
        this.pageWidth = 1920;
      }
      if (window.innerWidth <= 750) {
        this.sectionindex = 0;
        this.m_group_2_height = pageHeight;
        this.w750 = 1;
      } else {
        this.m_group_2_height = 0;
        this.w750 = 0;
      }
      // console.log("resize", this.pageWidth);
      // setTimeout(() => {
      $("html").css({ fontSize: this.pageWidth / 192 });
      // }, 500);

      // setTimeout(() => {
      //   that.resizepage();
      // },100);

      if (that.w750 == 0) {
        // if (that.swiper2) {
        //   that.swiper2.destroy(false, true);
        // }
        that.myswiper2();
      } else {
        if (that.swiper2) {
          that.swiper2.destroy(false, true);
        }
        that.myswiper2m();
      }
    },
    handleScroll(e) {
      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        global.setIndexscroll(winHeight);
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },
    changelag(lag) {
      if (lag == "en") {
        if (this.lag != "en") {
          this.$router.push("index/en");
          setTimeout(() => {
            location.reload();
          }, 100);
        }
      } else {
        if (this.lag == "en") {
          this.$router.push("/index");
          setTimeout(() => {
            location.reload();
          }, 100);
        }
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      // 获取元素在页面中的位置
      // console.log(this.$refs.dingyue_box_ref.getBoundingClientRect());

      // document
      //   .getElementById("agentTitle")
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      // 跳转到指定位置并且平滑滚动
      // console.log(this.$el.querySelector(`.${classtext}`));
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },
    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/index.css" />
<style src="../assets/css/index1280.css" />
<style src="../assets/css/index750.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
